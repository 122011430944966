import React, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LayoutCekilis from "components/layout-cekilis"
import { RaffleTypes } from "components/layout-cekilis"
import SEO from "components/seo"
import ShareButton from "components/share-button"
import { ShuffleArray } from "utils/common"
import { faBolt } from "@fortawesome/free-solid-svg-icons"
import styles from "./index.module.scss"
import useTranslations from "components/use-translations"

export default function Cekilis() {
  const [startedAsEmpty, setStartedAsEmpty] = useState(false)

  const [winnerCount, setWinnerCount] = useState(1)
  const [isReserveWinner, setIsReserveWinner] = useState(false)
  const [participants, setParticipants] = useState([])

  const [winners, setWinners] = useState([])
  const [reserveWinners, setReserveWinners] = useState([])

  const [fade, setFade] = useState(false)

  const startRaffle = () => {
    let participantsCopy = participants.filter(p => p !== "")
    if (participantsCopy.length === 0) {
      setStartedAsEmpty(true)
      return
    }
    setStartedAsEmpty(false)
    participantsCopy = ShuffleArray(participantsCopy)
    setWinners(participantsCopy.slice(0, winnerCount))
    setReserveWinners(
      !isReserveWinner
        ? []
        : participantsCopy.slice(winnerCount, winnerCount * 2)
    )
    document.getElementById("cekilis-sonucu").style.display = "block"
    setFade(true)
  }
  const {
    cekilis: {
      hizliSeoTitle,
      hizliSeoDesc,
      hizliCekilisYap,
      katilimciListesiPlaceHolder,
      kazanacakKisiSayisi,
      yedekKazananlarOlsun,
      cekilisiBaslat,
      kazananlar,
      yedekKazananlar,
    },
  } = useTranslations()
  return (
    <LayoutCekilis
      headerText={hizliCekilisYap}
      howToLink="https://www.kodvizit.com/cekilis/resim/hizli-cekilis.gif"
      type={RaffleTypes.Hizli}
    >
      <SEO
        title={hizliSeoTitle}
        description={hizliSeoDesc}
        pathname="https://www.kodvizit.com/cekilis"
        datePublished="2017-02-08T22:39:04+00:00"
        dateModified="2020-08-05T16:53:13+00:00"
      />
      <textarea
        placeholder={katilimciListesiPlaceHolder}
        className={`form-control mb-4 text-secondary ${
          startedAsEmpty && "is-invalid"
        } `}
        id="katilimcilar"
        name="katilimcilar"
        rows="8"
        onChange={e => {
          if (startedAsEmpty) setStartedAsEmpty(false)
          setParticipants(e.target.value.split("\n"))
        }}
        value={participants.join("\n")}
        spellCheck="false"
      />
      <div className="d-flex flex-column ">
        <div className="d-flex flex-row justify-content-center  ">
          <label
            htmlFor="kazanan"
            className=" font-weight-bold text-dark  mt-1 mb-0"
          >
            {kazanacakKisiSayisi}:
          </label>
          <input
            style={{ maxWidth: 210 }}
            type="number"
            min="1"
            value={winnerCount}
            name="kazanan"
            onChange={e => {
              setWinnerCount(e.target.value)
            }}
            id="kazanan"
            className="form-control mx-sm-3"
          />
        </div>
        <div className="d-flex flex-row justify-content-center  mt-2 ">
          <label
            htmlFor="kazanan"
            className=" font-weight-bold text-dark  mt-1"
          >
            {yedekKazananlarOlsun}:
          </label>
          <input
            type="checkbox"
            style={{ width: 20 }}
            name="yedek"
            id="yedek"
            className="form-control  ml-2"
            onChange={e => {
              setIsReserveWinner(e.target.checked)
            }}
            checked={isReserveWinner}
          />
        </div>
        <div className="d-flex justify-content-center">
          <button onClick={startRaffle} className="btn btn-info mb-4 mt-3">
            <FontAwesomeIcon icon={faBolt} className="mr-1" />
            <span>{cekilisiBaslat}</span>
          </button>
        </div>
        <div id="cekilis-sonucu" className="" style={{ display: "none" }}>
          <div className="d-flex flex-column justify-content-center align-items-center mb-5 mt-3">
            <div
              className={`py-3 rounded border border-gray text-center ${
                fade && styles.fade
              }`}
              onAnimationEnd={() => setFade(false)}
              style={{
                width: 400,
                backgroundColor: "rgb(255, 221, 170)",
              }}
            >
              <div className="font-weight-bold">{kazananlar}:</div>
              {winners.map((x, i) => (
                <div className="mb-0" key={i}>
                  {i + 1}) {x}
                </div>
              ))}
              {isReserveWinner && reserveWinners.length > 0 && (
                <div className="font-weight-bold mt-3">{yedekKazananlar}:</div>
              )}
              {isReserveWinner &&
                reserveWinners.map((x, i) => (
                  <div className="mb-0" key={i}>
                    {i + 1}) {x}
                  </div>
                ))}
            </div>
            <div className="mt-3">
              <ShareButton
                type={RaffleTypes.Hizli}
                participants={participants}
                winners={winners}
                reserveWinners={reserveWinners}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutCekilis>
  )
}
